import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import img1 from '../assets/images/img1.jpg';

function AppAbout() {
  const html = 80;
  const responsive = 95;
  const photoshop = 80;

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>Tambuli Group of Co ltd is an export company based in Dar es salaam Tanzania, The Group’s activities encompass Export and supply of AGRICULTURAL COMMODITIES like sesame seeds, cocoa, pigeon peas, yellow gram, cardamoms, green moong, groundnuts, soya beans, red beans, maize, Rice.</p>
            <p>and SEA PRODUCTS like Seashells (white & Tiger shells),and Seaweed, We also have FOREST PRODUCT which is Gum Arabic from Tanzania. Established in 2012, we are dedicated towards providing an extensive range of high quality of products at industry leading prices.</p>
            <p>Our team of skilled professionals is engaged in checking all the products on various quality parameters to ensure their high standards. We take care of the fact that customer satisfaction is very important In addition, we strive to maintain the proper hygiene and quality during each stages of preparation until they are packed and dispatched.</p>
            <div className='progress-block'>
              <h4>Sea Products</h4>
              <ProgressBar now={html} label={`${html}%`} />
            </div>
            <div className='progress-block'>
              <h4>Crops</h4>
              <ProgressBar now={responsive} label={`${responsive}%`} />
            </div>
            <div className='progress-block'>
              <h4>Spices</h4>
              <ProgressBar now={photoshop} label={`${photoshop}%`} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;