import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const blogData = [
  {
    id: 1,
    image: require('../assets/images/m2.jpg'),
    time: '15 Nov 2022',
    title: 'Spices for Life ',
    description: 'We supply and provide best services to our customers and client good services better archivement',
    link: 'https://www.tmbl.co.tz'
  },
  {
    id: 2,
    image: require('../assets/images/img7.jpg'),
    time: '10 Nov 2023',
    title: 'Crops for Health',
    description: 'We supply and provide best services to our customers and client good services better archivement',
    link: 'https://www.tmbl.co.tz'
  },
  {
    id: 3,
    image: require('../assets/images/m16.jpg'),
    time: '07 jan 2024',
    title: 'Beautiful and Decoration',
    description: 'We supply and provide best services to our customers and client good services better archivement',
    link: 'https://www.tmbl.co.tz'
  }
]

function AppBlog() {
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Latest from blog</h2>
          <div className="subtitle">get our latest news from blog</div>
        </div>
        <Row>
          {
            blogData.map(blog => {
              return (
                <Col sm={4} key={blog.id}>
                  <div className='holder'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <time>{blog.time}</time>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                        <a href={blog.link} className="btn btn-primary">Read More <i className="fas fa-chevron-right"></i></a>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default AppBlog;