import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/img-hero1.jpg'),
    title: 'WE SUPPLY CROPS',
    description: 'We are the best company in East Africa for exeporting diffrent products from Tanzania and providing best Services Outside and Within the country and good Corpation from other Company for archiving our company goals',
    link: 'www.tmbl.co.tz'
  },
  {
    id: 2,
    image: require('../assets/images/img-hero2.jpg'),
    title: 'WE SUPPLY SEA PRODUCTS',
    description: 'We are the best company in East Africa for exeporting diffrent products from Tanzania and providing best Services Outside and Within the country and good Corpation from other Company for archiving our company goals',
    link: 'www.tmbl.co.tz'
  },
  {
    id: 3,
    image: require('../assets/images/img-hero3.jpg'),
    title: 'WE SUPPLY SPICES',
    description: 'We are the best company in East Africa for exeporting diffrent products from Tanzania and providing best Services Outside and Within the country and good Corpation from other Company for archiving our company goals',
    link: 'www.tmbl.co.tz'
  },
  {
    id: 4,
    image: require('../assets/images/my10.jpg'),
    title: 'WE SUPPLY SPICES',
    description: 'We are the best company in East Africa for exeporting diffrent products from Tanzania and providing best Services Outside and Within the country and good Corpation from other Company for archiving our company goals',
    link: 'www.tmbl.co.tz'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    <a className="btn btn-primary" href={hero.link}>Learn More <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;