import image1 from '../assets/images/m16.jpg'
import image2 from '../assets/images/m13.jpg'
import image3 from '../assets/images/m17.jpg'
import image4 from '../assets/images/m8.jpg'
import image5 from '../assets/images/m7.jpg'
import image6 from '../assets/images/m9.jpg'
import image7 from '../assets/images/m2.jpg'
import image8 from '../assets/images/m5.jpg'
import image9 from '../assets/images/m9.jpg'
import image10 from '../assets/images/m12.jpg'
import image11 from '../assets/images/m10.jpg'
import image12 from '../assets/images/m1.jpg'

export default [image1, image2, image3, image4, image5, image6,image7,image8,image9,image10,image11,image12];