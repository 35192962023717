import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-exchange',
    title: 'Export Product Category',
    description: 'Export product categories refer to the classification of goods that are produced within a country and then sold to customers in other countries.  This category includes agricultural commodities and  and Agri-food Products'
  },
  {
    id: 2,
    icon: 'fas fa-snowflake',
    title: ' Import Product Category ',
    description: 'Importing product commodities for a company involves a similar process to importing product categories or commodities the focus here is on importing commodities that the company deals with or trades'
  },
  {
    id: 3,
    icon: 'fas fa-ship',
    title: 'shipping',
    description: 'Shipping within a company typically we deal with sending products or materials from one location to another within the companys supply chain this involve many stages include moving raw materials to production facilities, transporting finished goods to distribution centers or retail outlets, and sending products to customers directly.'
  },
  {
    id: 4,
    icon: 'fas fa-product-hunt',
    title: 'production',
    description: 'We deal with  manufacturing or acquiring goods that are generally uniform in nature and can be traded or sold in the marketplace. there is many procudere we follow in this production Sourcing Raw Materials,Manufacturing,Quality Control,Packaging,Distribution and Logistics'
  },
  {
    id: 5,
    icon: 'fas fa-industry',
    title: 'manufacturing',
    description: 'We producing goods on a large scale, typically in factories or industrial settings, with the aim of meeting the demands of consumers. Commodities are goods that are generally uniform in quality and are produced in large quantities, such as raw materials like metals, agricultural products like grains, or processed goods'
  },
  {
    id: 6,
    icon: 'fas fa-briefcase',
    title: 'More Services',
    description: 'More Services include customer consaltant about Agriculture production and manufacturing of goods'
  }
]

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Our services</h2>
          <div className="subtitle">services we provide</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder' key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;