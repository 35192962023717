import "./testmo.css"
import {motion} from 'framer-motion';
import {useRef, useEffect, useState} from "react";
import images from "./images"
function AppView() {

  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() =>{

    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
     
  }, [])
  return (
    <section id="view" className="block services-block">
  <div className="Apps">
    <div className="content"><h3>Our Product and Commodities Supplied</h3></div>
    <motion.div ref={carousel} className="ground carousel" whileTap={{cursor: "grabbing"}}>
      <motion.div drag="x" 
      dragConstraints={{right: -30, left: -width}}
      className="inner-carousel">
        {images.map(image =>{
          return(
             <motion.div className="item" key={image}> 
              <img src={image} alt="" />
             </motion.div>
          )
        })}
      </motion.div>
    </motion.div>
  </div>
  </section>
  );
}

export default  AppView;